const styles = (theme) => {
  return {
    root: {
      marginBottom: '10px',
      width: '100%',
    },
  };
};

export default styles;
